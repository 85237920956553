<template>
  <base-toggle
    :aria-label="accessibilityLabel"
    v-bind="{ modelValue, disabled }"
    class="i-flex"
    data-test-id="vf-switch"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <span
      aria-hidden="true"
      class="flex items-center pr-2 text-sm c-grey-10"
      :class="{ 'c-grey-20 ': disabled }"
      data-test-id="vf-switch-label-off"
    >
      {{ labelOff }}
    </span>
    <span
      class="w-14 flex rounded-full duration bg-grey-10 peer-focus-visible:outline-auto "
      :class="[
        modelValue ? 'bg-brand-1 ' : 'bg-grey-60 !bg-grey-30 ',
        { 'c-grey-70 ': disabled, '!bg-grey-60': disabled && modelValue },
      ]"
      style="padding: 0.1875rem 0.25rem;"
      data-test-id="vf-switch-track"
    >
      <span
        class="h-6 w-6 transform rounded-full bg-white duration "
        :class="{ 'translate-x-full': modelValue }"
        data-test-id="vf-switch-handle"
      />
    </span>
    <span
      aria-hidden="true"
      class="flex items-center pl-2 text-sm c-grey-10"
      :class="{ 'c-grey-20 ': disabled }"
      data-test-id="vf-switch-label-on"
    >
      {{ labelOn }}
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = defineProps<{
  /**
   * Sets the current value of the switch
   */
  modelValue?: boolean
  /**
   * Sets the disabled state of the switch
   */
  disabled?: boolean
  /**
   * Label for 'off' position of the switch
   */
  labelOff?: string
  /**
   * Label for 'on' position of the switch
   */
  labelOn?: string
}>()

defineEmits<{
  /**
   * Emits the active value on each change
   */
  (e: 'update:modelValue', payload: boolean | string[]): void
}>()

const { $t } = useNuxtApp()

const accessibilityLabel = computed(() => {
  if (props.labelOff && props.labelOn) {
    return replaceAll($t.switchAccessibilityLabel, {
      labelFrom: props.modelValue ? props.labelOn : props.labelOff,
      labelTo: props.modelValue ? props.labelOff : props.labelOn
    })
  }

  if (props.labelOff || props.labelOn)
    return replaceAll($t.switchAccessibilityLabelSingleLabel, { label: props.labelOff || props.labelOn })

  return $t.switchAccessibilityLabelNoLabels
})
</script>
